<template>
  <div>
    <b-dropdown-item @click="getResource" v-b-modal="`bannerEditModal${data.id}`"><i class="fas fa-edit mr-1 text-warning" />
      แก้ไข
    </b-dropdown-item>

    <b-modal v-model="modal" size="lg" :id="`bannerEditModal${data.id}`" title="แก้ไขประกาศ">
      <div class="row">
        <div class="col-12">
          <b-form-group label="เลือกไฟล์" label-for="image_link">
            <b-form-file v-model="form.file" placeholder="เลือกไฟล์รูปภาพ..." accept="image/*"></b-form-file>
          </b-form-group>
        </div>
        <div class="col-3">
          <b-form-group :label="`หน้า`" label-for="game_category_id">
            <b-form-select v-model="form.show" :options="showOptions"></b-form-select>
          </b-form-group>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-4">
              <b-form-group :label="`ประเภทเกม`" label-for="game_category_id">
                <b-form-select v-model="data.game_category_id" :options="gameCategories" text-field="name_th" value-field="id" @change="categoryChange"></b-form-select>
              </b-form-group>
            </div>
            <div class="col-4" v-if="data.game_category_id">
              <b-form-group :label="`เกม`" label-for="game_product_id">
                <b-form-select v-model="data.game_product_id" :options="gameProducts" text-field="product_name" value-field="id" @change="productChange"></b-form-select>
              </b-form-group>
            </div>
            <div class="col-4" v-if="data.game_category_id === 3 && data.game_product_id">
              <b-form-group :label="`สล็อท`" label-for="game_id">
                <b-form-select v-model="data.game_id" :options="games" text-field="game_name" value-field="id"></b-form-select>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <b-button variant="success" @click="submit()">
          ยืนยัน
        </b-button>
        <b-button variant="danger" @click="cancel()">
          ยกเลิก
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormFile, BDropdownItem, BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment-timezone';
import 'flatpickr/dist/flatpickr.css';
export default {
  components: {
    BFormFile, BDropdownItem, BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
    flatPickr
  },
  directives: {
    Ripple
  },
  props: ['data', 'gameCategories', 'gameProducts', 'games'],
  emits: ['submit', 'getProducts', 'getGames'],
  data: () => ({
    modal: false,
    file: null,
    showOptions: [
      { text: 'หน้าแรก', value: 'INDEX' },
      { text: 'หน้าเล่น', value: 'LOBBY' },
    ]
  }),
  mounted() {
    console.log(this.$props.data.id)
  },
  methods: {
    submit() {
      this.$emit('submit', { ...this.$props.data, ...this.file})
      this.modal = false
    },
    getResource() {
      this.$emit('getProducts', this.$props.data.game_category_id)
      this.$emit('getGames', this.$props.data.game_product_id)
    },
    categoryChange(e) {
      this.$emit('getProducts', e)
    },
    productChange(e) {
      if(this.$props.data.game_category_id === 3) {
        this.$emit('getGames', e)
      }
    },
    cancel() {
      this.modal = false;
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>