<template>
  <div>
    <b-button v-b-modal.createPredictionModal v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="gradient-primary"
      block>
      เพิ่มแบนเนอร์
    </b-button>

    <b-modal v-model="modal" size="lg" id="createPredictionModal" title="เพิ่มแบนเนอร์">
      <div class="row">

        <div class="col-12">
          <b-form-group label="เลือกไฟล์" label-for="image_link">
            <b-form-file v-model="form.file" placeholder="เลือกไฟล์รูปภาพ..." accept="image/*"></b-form-file>
          </b-form-group>
        </div>
        <div class="col-3">
          <b-form-group :label="`หน้า`" label-for="game_category_id">
            <b-form-select v-model="form.show" :options="showOptions"></b-form-select>
          </b-form-group>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-4">
              <b-form-group :label="`ประเภทเกม`" label-for="game_category_id">
                <b-form-select v-model="form.game_category_id" :options="gameCategories" text-field="name_th"
                  value-field="id" @change="categoryChange"></b-form-select>
              </b-form-group>
            </div>
            <div class="col-4" v-if="form.game_category_id">
              <b-form-group :label="`เกม`" label-for="game_product_id">
                <b-form-select v-model="form.game_product_id" :options="gameProducts" text-field="product_name"
                  value-field="id" @change="productChange"></b-form-select>
              </b-form-group>
            </div>
            <div class="col-4" v-if="form.game_category_id === 3 && form.game_product_id">
              <b-form-group :label="`สล็อท`" label-for="game_id">
                <b-form-select v-model="form.game_id" :options="games" text-field="game_name"
                  value-field="id"></b-form-select>
              </b-form-group>
            </div>
          </div>
        </div>
        <!-- <div class="col-12">
          <b-form-group label="สถานะ" label-for="image_link">
            <b-form-checkbox v-model="form.status" name="check-button" switch>
              ปิด/เปิด
            </b-form-checkbox>
          </b-form-group>
        </div> -->
      </div>

      <template #modal-footer>
        <b-button variant="success" @click="submit()">
          ยืนยัน
        </b-button>
        <b-button variant="danger" @click="cancel()">
          ยกเลิก
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormFile, BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment-timezone';
import 'flatpickr/dist/flatpickr.css';
export default {
  components: {
    BFormFile, BFormTextarea, BAlert, BSpinner, BFormCheckboxGroup, BFormCheckbox, BListGroupItem, BListGroup, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
    flatPickr
  },
  directives: {
    Ripple
  },
  props: ['competitions', 'gameCategories', 'gameProducts', 'games'],
  emits: ['submit', 'getProducts', 'getGames'],
  filters: {
    convertTime(date) {
      return moment(date).tz('Asia/Bangkok').format('HH:ss')
    },
  },
  data: () => ({
    modal: false,
    form: {
      id: null,
      show: 'INDEX',
      file: null,
      game_category_id: '',
      game_product_id: '',
      game_id: '',
    },
    showOptions: [
      { text: 'หน้าแรก', value: 'INDEX' },
      { text: 'หน้าเล่น', value: 'LOBBY' },
    ]
  }),
  methods: {
    submit() {
      this.$emit('submit', this.form)
      this.modal = false
    },
    categoryChange(e) {
      this.$emit('getProducts', e)
    },
    productChange(e) {
      if (this.form.game_category_id === 3) {
        this.$emit('getGames', e)
      }
    },
    cancel() {
      this.modal = false;
    }
  }
}
</script>


<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>